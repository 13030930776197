const Module = () => import("./Module.vue");
const Authenticate = () => import("./views/Authenticate.vue");

const routes = [
  {
    path: "/authenticate",
    component: Module,
    meta: {
      requiresAnonymous: true,
    },
    children: [
      {
        path: "/",
        component: Authenticate,
      },
    ],
  },
];

export default routes;
