const base64url = require("base64url");
const crypto = require("crypto");
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import IAuthService from "./IAuthService";
import httpService, {
  IHttpServiceAuthHandler,
  HttpServiceError,
} from "./HttpService";
import User from "@/models/User";
import OAuthToken from "@/models/OAuthToken";
import { OAuth2Client } from "@byteowls/capacitor-oauth2";

class OAuthAuthService implements IAuthService, IHttpServiceAuthHandler {
  static readonly storageTokenKey = "oauth";
  oauth_client_id = "";

  async init(): Promise<User> {
    const auth = await this._loadTokenFromStorage();
    if (!auth) {
      throw new HttpServiceError("Token missing", 401);
    }

    httpService.accessToken = auth.access_token;

    if (auth.expires_at_str) {
      const expires_at = new Date(auth.expires_at_str);
      const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);
      if (expires_at < tomorrow) {
        await this.refreshToken(auth.refresh_token);
      }
    }

    const resp = await httpService.get<User>("/oauth/userinfo");
    return resp.data;
  }

  async login(): Promise<User> {
    const response = await this._authenticate();
    const auth = response.access_token_response as OAuthToken;
    httpService.accessToken = auth.access_token;
    await this._saveTokenToStorage(auth);

    return this._createUserFromOAuthResponse(response);
  }

  async convertAnonymous(): Promise<User> {
    const response = await this._authenticate();
    const auth = response.access_token_response as OAuthToken;

    const form = new FormData();
    form.append("access_token", auth.access_token);

    await httpService.post<OAuthToken>("/oauth/convert-anonym", form);

    httpService.accessToken = auth.access_token;
    await this._saveTokenToStorage(auth);

    return this._createUserFromOAuthResponse(response);
  }

  _createUserFromOAuthResponse(response: any): User {
    const user = new User();
    user.email = response.email;
    user.roles = response.roles;
    user.anonymous = response.anonymous;
    return user;
  }

  async _authenticate(): Promise<any> {
    const baseUrl = httpService.baseURL;
    const options = {
      authorizationBaseUrl: `${baseUrl}/oauth/authorize`,
      accessTokenEndpoint: `${baseUrl}/oauth/token`,
      scope: "openid profile user:read user:write",
      resourceUrl: `${baseUrl}/oauth/userinfo`,
      logsEnabled: true,
      appId: this.oauth_client_id,
      android: {
        responseType: "code",
        pkceEnabled: true,
        redirectUrl: "de.danielgrams.cityservice:/",
      },
      ios: {
        responseType: "code",
        pkceEnabled: true,
        redirectUrl: "de.danielgrams.cityservice:/",
      },
    };

    return await OAuth2Client.authenticate(options);
  }

  async registerAnonymously(): Promise<User> {
    const code_verifier = base64url(crypto.pseudoRandomBytes(32));
    const code_challenge = base64url(
      crypto.createHash("sha256").update(code_verifier).digest()
    );

    const redirect_uri = `${httpService.baseURL}/.well-known/openid-configuration`;
    const code = await this._requestAuthorizationCodeAnonymously(
      redirect_uri,
      code_challenge
    );

    if (code == null) {
      throw new Error("OAuth code is missing");
    }

    const auth = await this._requestTokenForAuthorizationCode(
      code,
      code_verifier,
      redirect_uri
    );
    httpService.accessToken = auth.access_token;
    await this._saveTokenToStorage(auth);

    const response = await httpService.get<User>("/oauth/userinfo");
    return response.data;
  }

  supportsUnauthorizedRetry(): boolean {
    return true;
  }

  async prepareUnauthorizedRetry(): Promise<void> {
    const auth = await this._loadTokenFromStorage();
    if (!auth) {
      throw new Error("Auth info not available");
    }

    await this.refreshToken(auth.refresh_token);
  }

  async refreshToken(refreshToken: string): Promise<void> {
    const form = new FormData();
    form.append("client_id", this.oauth_client_id);
    form.append("grant_type", "refresh_token");
    form.append("refresh_token", refreshToken);

    try {
      const resp = await httpService.post<OAuthToken>("/oauth/token", form, {
        unauthorizedHandled: true,
      });
      const auth = resp.data;
      httpService.accessToken = auth.access_token;
      await this._saveTokenToStorage(auth);
    } catch (error) {
      if (
        error instanceof HttpServiceError &&
        error.status >= 400 &&
        error.status < 500
      ) {
        await this.reset();
        throw new HttpServiceError("Token could not be refreshed", 401);
      } else {
        throw error;
      }
    }
  }

  async logout(): Promise<void> {
    await this.reset();
  }

  async reset(): Promise<void> {
    await this._removeTokenFromStorage();
    httpService.accessToken = undefined;
    return Promise.resolve();
  }

  private async _requestTokenForAuthorizationCode(
    code: string,
    code_verifier: any,
    redirect_uri: string
  ): Promise<OAuthToken> {
    const form = new FormData();
    form.append("client_id", this.oauth_client_id);
    form.append("grant_type", "authorization_code");
    form.append("code", code);
    form.append("code_verifier", code_verifier);
    form.append("redirect_uri", redirect_uri);

    const response = await httpService.post<OAuthToken>("/oauth/token", form);
    return response.data;
  }

  private async _requestAuthorizationCodeAnonymously(
    redirect_uri: string,
    code_challenge: any
  ): Promise<string | null> {
    const form = new FormData();
    form.append("response_type", "code");
    form.append("client_id", this.oauth_client_id);
    form.append("scope", "openid profile user:read user:write");
    form.append("redirect_uri", redirect_uri);
    form.append("code_challenge", code_challenge);
    form.append("code_challenge_method", "S256");

    const resp = await httpService.post("/oauth/anonym", form);
    const url = new URL(resp.request.responseURL);
    const code = url.searchParams.get("code");
    return code;
  }

  private async _loadTokenFromStorage(): Promise<OAuthToken | undefined> {
    const key = OAuthAuthService.storageTokenKey;
    try {
      const { value } = await SecureStoragePlugin.get({
        key,
      });
      return JSON.parse(value);
    } catch {
      return undefined;
    }
  }

  private async _saveTokenToStorage(auth: OAuthToken): Promise<void> {
    const key = OAuthAuthService.storageTokenKey;

    if (auth.expires_in) {
      auth.expires_at_str = new Date(
        Date.now() + auth.expires_in * 1000
      ).toISOString();
    }

    const value = JSON.stringify(auth);
    await SecureStoragePlugin.set({ key, value });
  }

  private async _removeTokenFromStorage(): Promise<void> {
    const key = OAuthAuthService.storageTokenKey;
    SecureStoragePlugin.remove({ key });
  }
}

export default new OAuthAuthService();
