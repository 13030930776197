import Vue from "vue";
import App from "./App.vue";
import router from "./app/router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import moment from "moment";
import VueMoment from "vue-moment";
import VueMeta from "vue-meta";
import * as VeeValidate from "vee-validate";
import VueCookies from "vue-cookies";
import Vlf from "vlf";
import localforage from "localforage";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/de.json";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { Browser } from "@capacitor/browser";
import { Dialog } from "@capacitor/dialog";
import { App as NativeApp } from "@capacitor/app";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import "./custom.scss";
import i18n from "./i18n";
import store from "./store";
import httpService from "./services/HttpService";
import "./registerServiceWorker";
// @ts-ignore
import DefaultPage from "./components/DefaultPage";
// @ts-ignore
import DefaultList from "./components/DefaultList";
import CookieAuthService from "./services/CookieAuthService";
import OAuthAuthService from "./services/OAuthAuthService";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueCookies);
Vue.use(Vlf, localforage);

require("moment/locale/de");
moment.locale("de");
Vue.use(VueMoment, { moment });

Vue.$cookies.config("7d");

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("DefaultPage", DefaultPage);
Vue.component("DefaultList", DefaultList);

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    // @ts-ignore
    ...rules[rule], // @ts-ignore
    message: messages[rule],
  });
});

VeeValidate.extend("url", {
  validate: (value: any) => {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      );
    }

    /* istanbul ignore next */
    return false;
  },
});

/* istanbul ignore next */
if ("VUE_APP_BASE_URL" in process.env) {
  httpService.baseURL = process.env.VUE_APP_BASE_URL;
}

if ("VUE_APP_OAUTH_CLIENT_ID" in process.env) {
  OAuthAuthService.oauth_client_id = process.env.VUE_APP_OAUTH_CLIENT_ID;
}

const useOAuth = Capacitor.isNativePlatform();

if (useOAuth) {
  Vue.prototype.$authService = OAuthAuthService;
  httpService.withCredentials = false;
  httpService.authHandler = OAuthAuthService;
  store.dispatch("auth/setRegisterAnonymouslyAvailable", true);
  store.dispatch("auth/setLoggedInRequired", true);
} else {
  Vue.prototype.$authService = CookieAuthService;
  httpService.withCredentials = true;
}

const vue = new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
  methods: {
    handleHttpError(message: string) {
      this.makeErrorToast(message);
    },
    async handleUnauthorizedResponse(): Promise<void> {
      if (!store.getters["auth/isAuthenticated"]) {
        return;
      }

      await store.dispatch("auth/reset");
      this.$router.push({ path: "/authenticate" });
    },
    makeErrorToast(message: string) {
      this.makeToast(message, "danger", this.$t("shared.toast.errorTitle"));
    },
    /* istanbul ignore next */
    makeSuccessToast(message: string) {
      this.makeToast(message, "success", this.$t("shared.toast.successTitle"));
    },
    makeToast(message: string, variant: string, title: any) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        autoHideDelay: 3000,
        solid: true,
      });
    },
    /* istanbul ignore next */
    goBack(fallbackPath: string) {
      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push({ path: fallbackPath });
    },
    /* istanbul ignore next */
    openURL(originalURL: string) {
      let url = originalURL;

      if (url.startsWith(httpService.baseURL)) {
        url = url.replace(httpService.baseURL, "");
      }

      const resolved = router.resolve(url);

      if (resolved.route.name != "NotFound") {
        router.push(resolved.location);
      } else {
        Browser.open({ url: originalURL });
      }
    },
  },
}).$mount("#app");

httpService.handler = vue as any;

/* istanbul ignore next */
if (Capacitor.isPluginAvailable("PushNotifications")) {
  PushNotifications.addListener("registration", (token) => {
    PushNotifications.removeAllDeliveredNotifications();
    store
      .dispatch("notifications/handleRegistration", { token: token.value })
      .then(
        () => {
          // @ts-ignore
          if (store.state.notifications.registerAction == "registerPush") {
            vue.makeSuccessToast(
              // @ts-ignore
              i18n.t("app.user.profile.pushRegistrations.addedMessage")
            );
          }
        },
        (error) => {
          // @ts-ignore
          if (store.state.notifications.registerAction == "registerPush") {
            vue.makeErrorToast(error.message);
          }
        }
      );
  });

  PushNotifications.addListener("registrationError", (error) => {
    // eslint-disable-next-line no-console
    console.error("registrationError", JSON.stringify(error));
    store.dispatch("notifications/handleRegistrationError");
    vue.makeErrorToast(error.error);
  });

  PushNotifications.addListener("pushNotificationReceived", (notification) => {
    PushNotifications.removeAllDeliveredNotifications();

    const title = notification.title || "Goslar Service";
    const url = notification.data && notification.data.url;
    const message = notification.body || "";

    if (url == null) {
      Dialog.alert({
        title: title,
        message: message,
      });
      return;
    }

    Dialog.confirm({
      title: title,
      message: message,
    }).then((result) => {
      if (result.value) {
        vue.openURL(url);
      }
    });
  });

  PushNotifications.addListener("pushNotificationActionPerformed", (action) => {
    const notification = action.notification;
    const url = notification.data && notification.data.url;

    if (url != null) {
      vue.openURL(url);
    }
  });
}

/* istanbul ignore next */
if (Capacitor.isPluginAvailable("App")) {
  NativeApp.addListener("appStateChange", (state) => {
    store.commit("setAppIsActive", state.isActive);
    if (
      state.isActive &&
      Capacitor.isPluginAvailable("PushNotifications") &&
      // @ts-ignore
      store.state.notifications.notificationPermission == "granted"
    ) {
      PushNotifications.removeAllDeliveredNotifications();
    }
  });
}

if (Capacitor.isNativePlatform()) {
  CapacitorUpdater.notifyAppReady();
}
