import Vue from "vue";
import AuthStorage from "./AuthStorage";
import CodeError from "@/models/CodeError";
import { HttpServiceError } from "@/services/HttpService";

export const auth = {
  namespaced: true,
  state: {
    status: {
      requireLoggedIn: false,
      registerAnonymouslyAvailable: false,
      initialized: false,
      initializing: false,
      loggingIn: false,
      loggingOut: false,
      loggedIn: false,
      registering: false,
      registeringAnonymously: false,
    },
    user: null,
  },
  getters: {
    isRegisterAnonymouslyAvailable: (state) => {
      return state.status.registerAnonymouslyAvailable;
    },
    isLoggedInRequired: /* istanbul ignore next */ (state) => {
      return state.status.requireLoggedIn;
    },
    isAuthenticated: (state) => {
      return state.status.loggedIn;
    },
    isInitialized: (state) => {
      return state.status.initialized;
    },
    isAdmin: (state, getters) => {
      return (
        getters.isAuthenticated &&
        state.user &&
        state.user.roles &&
        state.user.roles.includes("admin")
      );
    },
    isAnonymous: (state, getters) => {
      return (
        getters.isAuthenticated &&
        state.user &&
        state.user.anonymous
      );
    },
    isAuthenticating: (state) => {
      return (
        state.status.initializing ||
        state.status.loggingIn ||
        state.status.registering ||
        state.status.registeringAnonymously
      );
    },
  },
  actions: {
    async init({ state, commit, dispatch }) {
      commit("initStart");
      let storedUser;
      let user;

      try {
        storedUser = await AuthStorage.loadUserFromStorage();

        if (!storedUser) {
          if (!state.status.registerAnonymouslyAvailable) {
            throw new Error();
          }
          /* istanbul ignore next */
          user = await Vue.prototype.$authService.registerAnonymously();
        } else {
          try {
            user = await Vue.prototype.$authService.init();
          } catch (error) {
            /* istanbul ignore next */
            if (
              error instanceof HttpServiceError &&
              error.status == 401 &&
              storedUser.anonymous &&
              state.status.registerAnonymouslyAvailable
            ) {
              await AuthStorage.removeUserFromStorage();
              user = await Vue.prototype.$authService.registerAnonymously();
            } else {
              throw error;
            }
          }
        }

        await AuthStorage.saveUserToStorage(user);
        commit("initSuccess", user);
        dispatch("notifications/init", null, { root: true });
        return user;
      } catch (error) {
        const authError =
          error instanceof HttpServiceError && /* istanbul ignore next */ error.status == 401;

        /* istanbul ignore next */
        if (authError) {
          await AuthStorage.removeUserFromStorage();
        }

        /* istanbul ignore next */
        if (storedUser && !authError) {
          commit("reset");
          dispatch("notifications/init", null, { root: true });
          throw new CodeError("NonAuthUser");
        }

        commit("initFailure");

        if (storedUser) {
          /* istanbul ignore next */
          if (authError) {
            const code = storedUser.anonymous
              ? "Auth401UserAnonymous"
              : "Auth401UserNotAnonymous";
            throw new CodeError(code);
          }
        }

        throw error;
      }
    },
    async login({ commit, dispatch }, { email, password }) {
      commit("loginStart");

      try {
        const user = await Vue.prototype.$authService.login(email, password);
        await AuthStorage.saveUserToStorage(user);
        commit("loginSuccess", user);
        dispatch("notifications/init", null, { root: true });
        return user;
      } catch (error) {
        commit("loginFailure");
        throw error;
      }
    },
    /* istanbul ignore next */
    async loginWithOAuth({ commit, dispatch }) {
      commit("loginStart");

      try {
        const user = await Vue.prototype.$authService.login();
        await AuthStorage.saveUserToStorage(user);
        commit("loginSuccess", user);
        dispatch("notifications/init", null, { root: true });
        return user;
      } catch (error) {
        commit("loginFailure");
        throw error;
      }
    },
    /* istanbul ignore next */
    async convertAnonymous({ commit }) {
      const user = await Vue.prototype.$authService.convertAnonymous();
      await AuthStorage.saveUserToStorage(user);
      commit("loginSuccess", user);
      return user;
    },
    async register({ commit, dispatch }, { email, password }) {
      commit("registerStart");

      try {
        const user = await Vue.prototype.$authService.register(email, password);
        await AuthStorage.saveUserToStorage(user);
        commit("registerSuccess", user);
        dispatch("notifications/init", null, { root: true });
        return user;
      } catch (error) /* istanbul ignore next */ {
        commit("registerFailure");
        throw error;
      }
    },
    /* istanbul ignore next */
    async registerAnonymously({ commit, dispatch }) {
      commit("registerAnonymouslyStart");

      try {
        const user = Vue.prototype.$authService.registerAnonymously();
        await AuthStorage.saveUserToStorage(user);
        commit("registerAnonymouslySuccess", user);
        dispatch("notifications/init", null, { root: true });
        return user;
      } catch (error) {
        commit("registerAnonymouslyFailure");
        throw error;
      }
    },
    async logout({ commit, dispatch }) {
      commit("logoutStart");

      try {
        await Vue.prototype.$authService.logout();
        await AuthStorage.removeUserFromStorage();
        commit("logoutSuccess");
        dispatch("notifications/init", null, { root: true });
      } catch (error) /* istanbul ignore next */ {
        commit("logoutFailure");
        throw error;
      }
    },
    /* istanbul ignore next */
    async reset({ commit, dispatch }) {
      await Vue.prototype.$authService.reset();
      await AuthStorage.removeUserFromStorage();
      commit("reset");
      dispatch("notifications/init", null, { root: true });
    },
    /* istanbul ignore next */
    setRegisterAnonymouslyAvailable({ commit }, available) {
      commit("setRegisterAnonymouslyAvailable", available);
    },
    /* istanbul ignore next */
    setLoggedInRequired({ commit }, required) {
      commit("setLoggedInRequired", required);
    },
  },
  mutations: {
    /* istanbul ignore next */
    setRegisterAnonymouslyAvailable(state, available) {
      state.status.registerAnonymouslyAvailable = available;
    },
    /* istanbul ignore next */
    setLoggedInRequired(state, required) {
      state.status.requireLoggedIn = required;
    },
    initStart(state) {
      state.status.initializing = true;
    },
    initSuccess(state, user) {
      state.status.initializing = false;
      state.status.initialized = true;
      state.status.loggedIn = true;
      state.user = user;
    },
    /* istanbul ignore next */
    reset(state) {
      state.status.initializing = false;
      state.status.initialized = false;
      state.status.loggedIn = false;
      state.status.registering = false;
      state.user = null;
    },
    initFailure(state) {
      state.status.initializing = false;
      state.status.initialized = true;
      state.status.loggedIn = false;
      state.user = null;
    },
    loginStart(state) {
      state.status.loggingIn = true;
    },
    loginSuccess(state, user) {
      state.status.loggingIn = false;
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggingIn = false;
      state.status.loggedIn = false;
      state.user = null;
    },
    registerStart(state) {
      state.status.registering = true;
    },
    registerSuccess(state, user) {
      state.status.registering = false;
      state.status.loggedIn = true;
      state.user = user;
    },
    /* istanbul ignore next */
    registerFailure(state) {
      state.status.registering = false;
    },
    /* istanbul ignore next */
    registerAnonymouslyStart(state) {
      state.status.registeringAnonymously = true;
    },
    /* istanbul ignore next */
    registerAnonymouslySuccess(state, user) {
      state.status.registeringAnonymously = false;
      state.status.loggedIn = true;
      state.user = user;
    },
    /* istanbul ignore next */
    registerAnonymouslyFailure(state) {
      state.status.registeringAnonymously = false;
      state.status.loggedIn = false;
      state.user = null;
    },
    logoutStart(state) {
      state.status.loggingOut = true;
    },
    logoutSuccess(state) {
      state.status.loggingOut = false;
      state.status.loggedIn = false;
      state.user = null;
    },
    /* istanbul ignore next */
    logoutFailure(state) {
      state.status.loggingOut = false;
    },
  },
};
