import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import User from "@/models/User";

export default class AuthStorage {
  static readonly storageUserKey = "user";

  static async loadUserFromStorage(): Promise<User | undefined> {
    const key = AuthStorage.storageUserKey;
    try {
      const { value } = await SecureStoragePlugin.get({
        key,
      });
      return JSON.parse(value);
    } catch {
      return undefined;
    }
  }

  static async saveUserToStorage(user: User): Promise<void> {
    const key = AuthStorage.storageUserKey;

    const value = JSON.stringify(user);
    await SecureStoragePlugin.set({ key, value });
  }

  static async removeUserFromStorage(): Promise<void> {
    const key = AuthStorage.storageUserKey;
    SecureStoragePlugin.remove({ key });
  }
}
