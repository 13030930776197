import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosError,
  AxiosResponse,
} from "axios";
import i18n from "@/i18n";

export interface IHttpServiceConfig {
  headers?: AxiosRequestHeaders;
  params?: any;
  suppressErrorToast?: boolean;
  unauthorizedHandled?: boolean;
  handleRequestStart?(): void;
  handleRequestFinish?(): void;
  handleLoading?(isLoading: boolean): void;
  handleRequestError?(error: Error, message: string): void;
}

export interface IHttpServiceHandler {
  handleHttpError(message: string): void;
  handleUnauthorizedResponse(): Promise<void>;
}

export interface IHttpServiceAuthHandler {
  supportsUnauthorizedRetry(): boolean;
  prepareUnauthorizedRetry(): Promise<void>;
}

export class HttpServiceError extends Error {
  status: number;

  constructor(msg: string, status: number) {
    super(msg);
    Object.setPrototypeOf(this, HttpServiceError.prototype);
    this.status = status;
  }
}

class HttpService {
  baseURL = "";
  withCredentials = true;
  handler?: IHttpServiceHandler;
  authHandler?: IHttpServiceAuthHandler;
  accessToken?: string;
  loggingEnabled = false;

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: IHttpServiceConfig
  ): Promise<R> {
    return this._getdelete("GET", url, config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: IHttpServiceConfig
  ): Promise<R> {
    return this._getdelete("DELETE", url, config);
  }
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data: any,
    config?: IHttpServiceConfig
  ): Promise<R> {
    return this._patchPutPost("POST", url, data, config);
  }

  /* istanbul ignore next */
  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data: any,
    config?: IHttpServiceConfig
  ): Promise<R> {
    return this._patchPutPost("PATCH", url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data: any = null,
    config?: IHttpServiceConfig
  ): Promise<R> {
    return this._patchPutPost("PUT", url, data, config);
  }

  private _getdelete<T = any, R = AxiosResponse<T>>(
    method: "GET" | "DELETE",
    url: string,
    config?: IHttpServiceConfig
  ): Promise<R> {
    const httpOptions = {
      method: method,
      url: this._buildAbsoluteUrl(url),
    } as AxiosRequestConfig<any>;

    if (config && config.headers) {
      httpOptions.headers = config.headers;
    }

    if (config && config.params) {
      httpOptions.params = config.params;
    }

    return this._request(httpOptions, config);
  }

  private _patchPutPost<T = any, R = AxiosResponse<T>>(
    method: "POST" | "PATCH" | "PUT",
    url: string,
    data: any,
    config?: IHttpServiceConfig
  ): Promise<R> {
    const headers = {
      "Content-Type": "application/json",
      ...((config && config.headers) || {}),
    };

    const httpOptions = {
      method: method,
      url: this._buildAbsoluteUrl(url),
      headers: headers,
    } as AxiosRequestConfig<any>;

    if (data != null) {
      httpOptions.data = data;
    }

    return this._request(httpOptions, config);
  }

  async _request<T = any, R = AxiosResponse<T>>(
    httpOptions: AxiosRequestConfig<any>,
    config?: IHttpServiceConfig
  ): Promise<R> {
    if (this.withCredentials) {
      httpOptions.withCredentials = true;
    }

    if (this.accessToken) {
      if (httpOptions.headers == null) {
        httpOptions.headers = {};
      }
      httpOptions.headers["Authorization"] = `Bearer ${this.accessToken}`;
    }

    if (httpOptions.params) {
      Object.keys(httpOptions.params).forEach((key) => {
        httpOptions.params[key] = "" + httpOptions.params[key];
      });
    }

    if (this.loggingEnabled) {
      console.log("Request", httpOptions, config);
    }

    this._handleHttpStart(config);

    try {
      const response = await axios.request<T, R>(httpOptions);
      if (this.loggingEnabled) {
        console.log("Response", response);
      }

      this._handleHttpFinish(config);
      return response;
    } catch (error: unknown) {
      if (this.loggingEnabled) {
        console.log(error);
      }

      const axiosError = error as AxiosError;
      if (
        this.accessToken &&
        axios.isAxiosError(axiosError) &&
        axiosError.response !== undefined &&
        axiosError.response.status == 401
      ) {
        this.accessToken = undefined;
        const unauthorizedHandled =
          config && config.unauthorizedHandled
            ? config.unauthorizedHandled
            : false;
        if (
          this.authHandler &&
          this.authHandler.supportsUnauthorizedRetry() &&
          !unauthorizedHandled
        ) {
          await this.authHandler.prepareUnauthorizedRetry();
          return this._request(httpOptions, config);
        }
      }

      this._handleHttpFinish(config);

      if (error instanceof Error) {
        this._handleHttpError(error, config);
      }

      throw error;
    }
  }

  private _handleHttpStart(config?: IHttpServiceConfig): void {
    if (!config) {
      return;
    }

    if (config.handleRequestStart) {
      config.handleRequestStart();
    }

    if (config.handleLoading) {
      config.handleLoading(true);
    }
  }

  private _handleHttpFinish(config?: IHttpServiceConfig): void {
    if (!config) {
      return;
    }

    if (config.handleRequestFinish) {
      config.handleRequestFinish();
    }

    if (config.handleLoading) {
      config.handleLoading(false);
    }
  }

  /* istanbul ignore next */
  private _handleHttpError(error: Error, config?: IHttpServiceConfig): void {
    let message = error.message;
    let status = 0;

    const axiosError = error as AxiosError;
    if (axios.isAxiosError(axiosError) && axiosError.response !== undefined) {
      status = axiosError.response.status;

      if (status == 400 || (status == 422 && axiosError.response.data)) {
        const data = axiosError.response.data;

        if (data.message) {
          message = data.message;
        }

        if (data.response && data.response.errors) {
          message = Object.values(data.response.errors).flat().join(". ");
        }

        const errorName = axiosError.response.data.name;

        if (errorName == "Unique Violation") {
          message = i18n.t("shared.errors.uniqueViolation") as string;
        } else if (errorName == "Unprocessable Entity") {
          message = i18n.t("shared.errors.unprocessableEntity") as string;
        }
      }

      if (status == 401 && this.handler) {
        this.handler.handleUnauthorizedResponse();
      }
    }

    if (config && config.handleRequestError) {
      config.handleRequestError(error, message);
    }

    const suppressErrorToast =
      config && config.suppressErrorToast ? config.suppressErrorToast : false;
    if (!suppressErrorToast) {
      this.handler?.handleHttpError(message);
    }

    if (status > 0) {
      throw new HttpServiceError(message, status);
    }

    throw error;
  }

  private _buildAbsoluteUrl(url: string): string {
    return `${this.baseURL}${url}`;
  }
}

export default new HttpService();
