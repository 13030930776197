import IAuthService from "./IAuthService";
import httpService from "./HttpService";
import User from "@/models/User";

class CookieAuthService implements IAuthService {
  async init(): Promise<User> {
    const resp = await httpService.get("/auth/login", {
      headers: { "Content-Type": "application/json" },
    });
    const csrf_token = resp.data["response"]["csrf_token"];
    const response = await httpService.get("/api/user", {
      headers: {
        "X-CSRF-Token": csrf_token,
      },
      suppressErrorToast: true,
    });
    return response.data;
  }

  async login(email: string, password: string) {
    const resp = await httpService.get("/auth/login", {
      headers: { "Content-Type": "application/json" },
    });
    const csrf_token = resp.data["response"]["csrf_token"];
    const resp_1 = await httpService.post(
      "/auth/login",
      {
        email: email,
        password: password,
        remember: true,
      },
      {
        headers: {
          "X-CSRF-Token": csrf_token,
        },
      }
    );
    return resp_1.data["response"]["user"];
  }

  async register(email: string, password: string) {
    const resp = await httpService.get("/auth/register", {
      headers: { "Content-Type": "application/json" },
    });
    const csrf_token = resp.data["response"]["csrf_token"];
    const resp_1 = await httpService.post("/auth/register", {
      email: email,
      password: password,
      remember: true,
      csrf_token: csrf_token,
    });
    return resp_1.data["response"]["user"];
  }

  async logout(): Promise<void> {
    await httpService.post("/auth/logout", null);
  }

  async reset(): Promise<void> {
    return Promise.resolve();
  }
}

export default new CookieAuthService();
