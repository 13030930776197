const Module = () => import("./Module.vue");
const Imprint = () => import("./views/Imprint.vue");
const Privacy = () => import("./views/Privacy.vue");
const TermsOfService = () => import("./views/TermsOfService.vue");

const routes = [
  {
    path: "/",
    component: Module,
    children: [
      {
        path: "/imprint",
        component: Imprint,
      },
      {
        path: "/privacy",
        component: Privacy,
      },
      {
        path: "/tos",
        component: TermsOfService,
      },
    ],
  },
];

export default routes;
