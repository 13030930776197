import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./app/auth/store";
import { notifications } from "./app/notifications/store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || "0",
    appIsActive: true,
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
  },
  mutations: {
    /* istanbul ignore next */
    setAppIsActive(state, isActive) {
      state.appIsActive = isActive;
    },
  },
  actions: {},
  modules: {
    auth,
    notifications,
  },
});
